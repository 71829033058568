<!--
 * ======================================
 * 说明： 家长管理
 * 作者： Silence
 * 文件： parents.vue
 * 日期： 2023/8/22 1:23
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :options="handleOptions" :autoLoading="true" :loadData="handleLoadData" title="家长管理" ref="table" :handleInfo="{label: '操作',width: 340,}">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="formSearch.name" placeholder="家长姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input v-model="formSearch.mobile" placeholder="家长电话"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" @click="handleCreate" icon="el-icon-edit">添加家长</el-button>
<!--            <el-button type="success" @click="handleExport" >批量导入模板</el-button>-->
<!--            <el-upload-->
<!--              action=""-->
<!--              :show-file-list="false"-->
<!--              style="display: contents"-->
<!--              accept=".xls,.XLS,.xlsx,.XLSX"-->
<!--              :http-request="handleImport"-->
<!--            >-->
<!--              <el-button icon="el-icon-upload2" type="primary" class="ml-2"-->
<!--              >批量导入-->
<!--              </el-button>-->
<!--            </el-upload>-->
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" @click="handleResetPassword(row)">重置密码</el-button>
          <el-button type="text" icon="el-icon-edit" @click="handleBindStudent(row)">绑定学生</el-button>
          <el-button type="text" icon="el-icon-edit" @click="handleUnbindStudent(row)">解绑学生</el-button>
          <el-button type="text" icon="el-icon-edit" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
    <bind-student ref="bindStudent" @refresh="handleRefresh"></bind-student>
  </div>
</template>
<script  type="text/javascript">
import edit from './components/edit.vue'
import bindStudent from './components/bind.vue'
import mixin from "@/mixin/execlExportImport";
export default {
  name: "panel",
  components: {
    edit,
    bindStudent
  },
  mixins: [mixin],
  data() {
    return {
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        name:'',
        mobile:''
      },
    };
  },
  methods: {
    handleOptions(table) {
      // console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/parents").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.get("parent/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    // 添加家长
    handleCreate() {
      this.$cloud.dialog({
        title: '添加家长信息',
        data: {},
        component: edit,
        success: '添加成功！',
        warning: '添加失败！',
        refresh: this.handleRefresh
      })
    },
    // 更新家长
    handleEdit(item) {
      this.$cloud.dialog({
        title: '更新家长信息',
        data: item,
        component: edit,
        success: '更新成功！',
        warning: '更新失败！',
        refresh: this.handleRefresh
      })
    },
    // 删除家长
    handleDelete(item) {
      this.$confirm('此操作将永久删除该家长, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("parent/remove", {id: item.id}).then(res => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },
  
  
    // 重置为初始密码
    handleResetPassword(row){
      this.$confirm('此操作将重置为初始密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        let id  = row.id
        this.$cloud.post("parent/reset/password",{id}).then(()=>{
          this.$message.success("重置成功，请牢记初始密码为：123456")
        })
      }).catch(()=>{})
    },

    //  绑定学生
    handleBindStudent(item) {
      this.$refs['bindStudent'].open({
        row: item,
        type:'bind'
      })
      // this.$cloud.dialog({
      //   title: '绑定学生',
      //   data: {
      //     type:'bind',
      //     row: item
      //   },
      //   component: bindStudent,
      //   success: '绑定成功!',
      //   warning: '绑定失败!',
      //   customClass: 'parents-bind-student',
      //   refresh: this.handleRefresh
      // })
    },

    // 解除绑定
    handleUnbindStudent(item){
      this.$refs['bindStudent'].open({
        row: item,
        type:'unbind'
      })
      // this.$cloud.dialog({
      //   title: '解除绑定',
      //   data: {
      //     type:'unbind',
      //     row: item
      //   },
      //   component: bindStudent,
      //   success: '解绑成功!',
      //   warning: '解绑失败!',
      //   customClass: 'parents-bind-student',
      //   refresh: this.handleRefresh
      // })
    },

    // 导出模板
    handleExport() {
      let tHeader = ['姓名',"电话"];
      this.exportElxs({
        header: tHeader, //这里应该是算第二行的表头
        data: [], //数据
        filename: `家长导入模板`,
        autoWidth: true,
        bookType: "xlsx",
      });
    },


    // 批量导入
    handleImport(e) {
      this.httpRequest(e,(data)=>{
        if(data && data.length){
          let params = {
            list:data.map(item=>{
              return {
                name: item['姓名'],
                mobile:item['电话'],
              }
            })
          }
          this.$cloud.post('parent/import',params).then(res=>{
            this.$message.success('导入成功!')
            this.handleRefresh()
          })
        }
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
};
</script>
<style lang="scss">
.parents-bind-student{
  width: 840px;
}
</style>
