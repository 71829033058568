<!--
 * ======================================
 * 说明： 绑定学生
 * 作者： Silence
 * 文件： bind.vue
 * 日期： 2023/8/23 10:40
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog :title="value.type === 'unbind'?'解绑学生':'绑定学生'" :visible.sync="visible" width="800px">
  <div v-loading="loading">
    <el-form :inline="true" :model="formSearch" v-if="value.type === 'bind'">
      <el-form-item label="班级">
        <el-cascader clearable v-model="classId" @change="handleClasses" :options="classesTree"
                     :props="{value:'id',label:'name'}" :show-all-levels="false"></el-cascader>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="formSearch.realName" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="学号">
        <el-input v-model="formSearch.number" placeholder="学号"></el-input>
      </el-form-item>
      <el-button type="primary" @click="handleSearchStudent">查询</el-button>
    </el-form>
    <el-table border height="360px" :data="studentData">
      <el-table-column type="selection" width="55"  v-if="value.type === 'unbind'"></el-table-column>
      <el-table-column type="index" label="序号" align="center"></el-table-column>
      <el-table-column v-if="value.type === 'unbind'" prop="name" label="角色"></el-table-column>
      <el-table-column prop="class.class_name" label="班级"></el-table-column>
      <el-table-column prop="student.number" label="学号"></el-table-column>
      <el-table-column prop="student.real_name" label="姓名"></el-table-column>
      <el-table-column label="操作" width="100px" align="center">
        <template slot-scope="scope">
          <el-button v-if="value.type === 'bind' && !scope.row.bind" size="mini" type="success" @click="handleBindStudent(scope.row,scope.$index)">
            绑定
          </el-button>
          <el-button v-if="value.type === 'unbind'" size="mini" type="danger" @click="handleUnbindStudent(scope.row)">
            解绑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  </Dialog>
</template>
<script>
import Dialog from '@/theme/school/components/Dialog/index.vue'
import {MessageBox} from "element-ui";

export default {
  name: '',
  components: { Dialog },
  props: {},
  data() {
    return {
      value: {},
      visible:false,
      bindIds: [],
      loading: false,
      classesTree: [],
      studentData: [],
      classId: [],
      parentsRole: '',
      formSearch: {
        realName: '',
        number: '',
        classId: '',
      },
      parents: {
        role: ''
      },
      rules: {
        role: [
          {required: true, message: '请输入家长角色', trigger: 'blur'},
        ]
      }
    }
  },
  computed: {},
  created() {
  },
  async mounted() {
    await this.getClassesTree();
  },
  methods: {
     open(value) {
      this.value = value;
      this.visible = true;

      if(this.value.type === 'unbind'){
        this.getBindList()
      }else{
        this.handleSearchStudent()
      }
    },

    // 获取班级待选树状数据
     getClassesTree() {
       this.$cloud.post('/classes/list/tree').then(res => {
        this.classesTree = res
      })
    },

    // 获取已绑定列表
     getBindList() {
       this.$cloud.get('parent/bind/list', {parentId: this.value.row.id}).then(res => {
        console.log('绑定的学生：：',res)
        this.studentData = res
      })
    },

    handleClasses() {
      this.formSearch['classId'] = ''
      if (this.classId.length) {
        this.formSearch['classId'] = this.classId[1]
      }
    },

    // 搜索学生
    handleSearchStudent() {
      // if(Object.values(this.formSearch).every(v=>v === '')){
      //   this.$message.warning('搜索条件不能为空')
      // }else{
      this.loading = true
      this.$cloud.get('/parent/student', {size: 99999, page: 1, ...this.formSearch}).then(res => {
        if (res.children?.length) {
          this.studentData = res.children
        } else {
          this.studentData = []
        }
        this.loading = false
      }).catch(err => {
        // this.$message.error(err)
        this.loading = false
      })
      // }
    },

    // 绑定学生
    handleBindStudent(item,index) {
      console.log("$object",this.$object)
      MessageBox.prompt('家长角色：', '绑定学生', {
        inputPlaceholder: '请输入家长角色',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        appendToBody: true,
        inputValidator: (value) => {       // 点击按钮时，对文本框里面的值进行验证
          if (!value) {
            return '输入不能为空';
          }
        },
        callback: (action, instance) => {
          if(action === 'confirm'){
            this.$cloud.post('/parent/bind/student', {
              parentId: this.value?.row?.id,
              studentId: item.id,
              name: instance.inputValue,
            }).then(res => {
              this.$message.success('绑定成功')
              this.studentData.splice(index,1,{
                ...item,
                  bind:true,
              })
            })
          }
        }
      })
    },

    // 解绑学生
    handleUnbindStudent(row) {
      console.log('解绑',row)
      this.$cloud.post('/parent/unbind/student',{parentId:row.parent_id,studentId:row.student_id}).then(() => {
        console.log("解绑")
        this.$message.success("解绑成功")
        this.getBindList()
      })
    },

    handleRefresh() {

    }
  },
}
</script>
<style lang="scss" scoped></style>
