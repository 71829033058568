<!--
 * ======================================
 * 说明： 家长  新增  编辑
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/8/22 1:25
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form :model="form" ref="form" label-width="80px" :rules="rules">
    <el-row>
      <el-col :span="24">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value: {
        type: Object
      }
    },
    data() {
      return {
        form:{
          name:'',
          mobile:'',
        },
        rules:{
          name:[{ required: true, message: '请输入姓名', trigger: 'blur' }],
          mobile:[
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
          ],
        }
      }
    },
    computed: {},
    created() {},
    mounted() {
      if(this.value.id){
        this.handleLoadForm(this.value.id);
      }else{
        this.form = {
          name: '',
          mobile: '',
        }
      }
    },
    methods: {
      // 加载表单数据
      handleLoadForm(id){
        this.$cloud.get('parent/detail',{id}).then(res=>{
          this.form = res
        })
      },
      
      // 提交数据
      handleSubmit(){
        return new Promise((resolve,reject)=>{
          this.$refs['form'].validate((valid)=>{
            if(valid){
              let api = this.form.id ? 'parent/edit' : 'parent/create'
              this.$cloud.post(api,this.form).then(()=>{
                resolve(true)
              }).catch(()=>{
                reject(false)
              })
            }else{
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>